import { graphql } from 'gatsby'
import { get, has } from 'lodash'
import React from 'react'

import Layout from 'components/layout/layout'
import Meta from 'components/meta/meta'

class Donate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedPillar: 'generalFund',
    }

    let donationPillars = get(this.props.data, 'page.relationships.field_donation_pillars')
    if (donationPillars !== undefined) {
      this.state = {
        selectedPillar: 0,
      }
    }
  }

  donationUrls = {}

  handleInputChange = (event) => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    window.open(this.donationUrls[this.state.selectedPillar], '_blank')
  }

  render() {
    const { location, data } = this.props
    const siteMetadata = get(data, 'site.meta')
    const banner = get(data, 'page.relationships.field_banner')
    let mainContent = get(data, 'page.field_main_content', '')
    if (mainContent && has(mainContent, 'processed')) {
      const drupalUrl = process.env.DRUPAL_URL || 'https://ispe.org'
      mainContent = mainContent.processed.replace(
        /\/sites\/default\/files/g,
        drupalUrl + '/sites/default/files'
      )
    }

    const donationPillars = get(data, 'page.relationships.field_donation_pillars', [])
    let donationOptions = []

    if (donationPillars) {
      // Organize / set data to be used.
      Object.keys(donationPillars).forEach((key) => {
        this.donationUrls[key] = donationPillars[key].field_donate_link.url
      })

      donationOptions = donationPillars.map((donationPillar, key) => (
        <option key={donationPillar.drupal_internal__id} value={key}>
          {donationPillar.field_pillar_label}
        </option>
      ))
    }

    return (
      <Layout banner={banner} location={location}>
        <Meta site={siteMetadata} title="Donate" />

        {mainContent && (
          <section>
            <div className="container">
              <div className="row mx-auto">
                <div className="col col-md-8 offset-md-2">
                  <div dangerouslySetInnerHTML={{ __html: mainContent }} />
                </div>
              </div>

              {donationPillars && (
                <div className="row mx-auto text-center mt-5">
                  <div className="col col-md-8 offset-md-2">
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-group">
                        <label className="h5">I Want to Support:</label>
                        <select
                          name="selectedPillar"
                          value={this.state.selectedPillar}
                          onChange={this.handleInputChange}
                          className="form-control form-control-lg"
                        >
                          {donationOptions}
                        </select>
                      </div>

                      <button type="submit" className="btn btn-lg btn-primary text-uppercase">
                        {donationPillars[this.state.selectedPillar].field_donate_link.title}
                      </button>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </section>
        )}
      </Layout>
    )
  }
}

export default Donate

export const DonatePageQuery = graphql`
  query DonatePageQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        drupalUrl
        author
        twitter
      }
    }
    page: configPagesFoundationDonatePage {
      field_main_content {
        processed
        summary
      }
      relationships {
        field_banner {
          field_slide_title
          field_slide_content {
            value
            processed
          }
          field_slide_cta {
            title
            url
          }
          field_slide_image {
            title
            alt
          }
          drupal_internal__id
          relationships {
            field_slide_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000, srcSetBreakpoints: [576, 768, 992, 1200]) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        field_donation_pillars {
          drupal_internal__id
          field_donate_link {
            title
            url
          }
          field_pillar_label
          id
        }
      }
    }
  }
`
