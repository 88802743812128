import React from 'react'
import { has } from 'lodash'

import Navi from 'components/navi/navi'
import { siteMetadata } from '../../../gatsby-config'
import BackgroundImage from 'gatsby-background-image'
import './banner-header.scss'

class BannerHeader extends React.Component {
  render() {
    const banner = this.props.banner
    const pageTitle = this.props.title ? this.props.title : siteMetadata.title

    // If a Banner does not exist, we can use a default image or an empty background.
    // @Todo: Confirm Default page.
    if (!has(banner, 'relationships.field_slide_image.localFile.childImageSharp')) {
      return (
        <header className="header-wrapper position-relative">
          <Navi {...this.props} />

          <div className="default-background-image">
            <div className="banner-content-wrapper">
              <div className="container">
                <div className="banner-content">
                  <h1 className="font-weight-bold">{pageTitle}</h1>
                </div>
              </div>
            </div>
          </div>
        </header>
      )
    }

    const bannerContent = banner.field_slide_content ? banner.field_slide_content.processed : undefined

    return (
      <header className="header-wrapper">
        <Navi title={siteMetadata.title} {...this.props} />

        <BackgroundImage
          Tag="div"
          fluid={banner.relationships.field_slide_image.localFile.childImageSharp.fluid}
          alt={banner.field_slide_image.alt}
        >
          <div className="jumbotron jumbotron-fluid text-white banner-content-wrapper">
            <div className="container">
              <div className="banner-content">
                <h1>{banner.field_slide_title}</h1>
                {bannerContent && (
                  <div
                    className="lead"
                    dangerouslySetInnerHTML={{
                      __html: banner.field_slide_content.processed,
                    }}
                  />
                )}
                {banner.field_slide_cta && (
                  <p>
                    <a
                      className="btn btn-lg btn-outline-light"
                      href={banner.field_slide_cta.url}
                      role="button"
                    >
                      {banner.field_slide_cta.title}
                    </a>
                  </p>
                )}
              </div>
            </div>
          </div>
        </BackgroundImage>
      </header>
    )
  }
}

export default BannerHeader
