import React from 'react'
import emergence from 'emergence.js'

import BannerHeader from 'components/header/banner-header'
import Footer from 'components/footer/footer'
import { siteMetadata } from '../../../gatsby-config'

class Layout extends React.Component {
  componentDidMount() {
    emergence.init()
  }

  componentDidUpdate() {
    emergence.init()
  }

  render() {
    const { children } = this.props
    return (
      <div>
        <BannerHeader title={this.props.title ? this.props.title : siteMetadata.title} {...this.props} />
        <main>{children}</main>
        <Footer />
      </div>
    )
  }
}

export default Layout
